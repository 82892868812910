<script setup lang="ts">
useHead({ title: "!Streamfold - Telemetry Pipeline for Developers" })
</script>

<template>
  <div>
    <!-- Hero -->
    <section
      class="bg-gradient-to-b from-indigo-25 to-white border-b border-indigo-50"
    >
      <div class="wrapper max-w-7xl mx-auto py-16 px-8">
        <div class="text-center">
          <h1
            class="text-6xl font-medium bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-violet-400 mx-auto pb-2"
          >
            A telemetry pipeline built for
            <span class="text-indigo-600">developers</span>.
          </h1>
          <div
            class="relative items-center justify-center mx-auto mt-12 max-w-5xl lg:max-w-5xl hidden lg:flex lg:mt-12"
          >
            <img src="/images/diagram-bg.png" alt="Streamfold" class="w-5xl" />
            <div
              class="w-[230px] h-[230px] absolute grid border border-indigo-50 rounded-full border-opacity-100 rotate-45"
            >
              <div class="w-full h-full absolute grid items-center rotate-0">
                <div class="reverse path w-full h-full rounded-full">
                  <div
                    class="bg-teal-100 bg-opacity-100 rounded-full w-2 h-2 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  ></div>
                </div>
              </div>
              <div class="w-full h-full absolute grid items-center rotate-90">
                <div class="reverse path w-full h-full rounded-full">
                  <div
                    class="bg-sky-100 bg-opacity-100 rounded-full w-2 h-2 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  ></div>
                </div>
              </div>
              <div class="w-full h-full absolute grid items-center rotate-180">
                <div class="reverse path w-full h-full rounded-full">
                  <div
                    class="bg-violet-100 bg-opacity-100 rounded-full w-2 h-2 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  ></div>
                </div>
              </div>
              <div
                class="w-full h-full absolute grid items-center rotate-[270deg]"
              >
                <div class="reverse path w-full h-full rounded-full">
                  <div
                    class="bg-indigo-100 bg-opacity-100 rounded-full w-2 h-2 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  ></div>
                </div>
              </div>
            </div>
            <div
              class="w-[200px] h-[200px] absolute grid border border-indigo-25 rounded-full border-opacity-100 rotate-0"
            >
              <div class="w-full h-full absolute grid items-center rotate-0">
                <div class="normal path w-full h-full rounded-full">
                  <div
                    class="bg-teal-100 bg-opacity-100 rounded-full w-3 h-3 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  ></div>
                </div>
              </div>
              <div class="w-full h-full absolute grid items-center rotate-90">
                <div class="normal path w-full h-full rounded-full">
                  <div
                    class="bg-sky-100 bg-opacity-100 rounded-full w-3 h-3 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  ></div>
                </div>
              </div>
              <div class="w-full h-full absolute grid items-center rotate-180">
                <div class="normal path w-full h-full rounded-full">
                  <div
                    class="bg-violet-100 bg-opacity-100 rounded-full w-3 h-3 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  ></div>
                </div>
              </div>
              <div
                class="w-full h-full absolute grid items-center rotate-[270deg]"
              >
                <div class="normal path w-full h-full rounded-full">
                  <div
                    class="bg-indigo-100 bg-opacity-100 rounded-full w-3 h-3 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  ></div>
                </div>
              </div>
            </div>
            <img
              src="/images/streamfold-centerpiece.png"
              alt="Streamfold"
              class="absolute h-[241px]"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- Benefits -->
    <section class="bg-white border-b border-indigo-50">
      <div
        class="wrapper max-w-7xl mx-auto px-8 py-16 grid grid-cols-1 md:grid-cols-3 gap-8"
      >
        <div>
          <h2
            class="text-2xl font-medium text-indigo-500 mb-4 pl-8 bg-[url('/icons/square-3-stack-3d.svg')] bg-left bg-no-repeat pb-0.5"
          >
            Bring your own stack
          </h2>
          <p class="text-slate-600 text-lg leading-6 mb-4">
            Full support for the most common telemetry tools. OpenTelemetry,
            Datadog, Elastic, Splunk, Amazon S3, Honeycomb, and more.
          </p>
        </div>
        <div>
          <h2
            class="text-2xl font-medium text-indigo-500 mb-4 pl-8 bg-[url('/icons/banknotes.svg')] bg-left bg-no-repeat pb-0.5"
          >
            Manage spend
          </h2>
          <p class="text-slate-600 text-lg leading-6 mb-4">
            Optimize the costs of your logs, metrics, and traces. Send vendors
            what you care about and eliminate the rest.
          </p>
        </div>
        <div>
          <h2
            class="text-2xl font-medium text-indigo-500 mb-4 pl-8 bg-[url('/icons/adjustments-horizontal.svg')] bg-left bg-no-repeat pb-0.5"
          >
            Take back ownership
          </h2>
          <p class="text-slate-600 text-lg leading-6 mb-4">
            Full-fidelity archives with cost-efficient cloud storage like S3.
            Have all the context you need for compliance and post incident
            analysis.
          </p>
        </div>
      </div>
    </section>
    <!-- Features -->
    <section class="bg-white">
      <div class="wrapper max-w-7xl mx-auto px-8 pt-16 pb-16">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div class="flex mb-6">
            <div>
              <h2 class="text-slate-600 text-lg font-medium inline">
                Flexible routing
              </h2>
              <p class="text-slate-400 text-lg inline">
                &mdash; Connect your telemetry to multiple destinations to
                empower teams with your data. No more need to build separate
                pipelines for observability, product, data science and customer
                success.
              </p>
            </div>
          </div>
          <div class="flex mb-6">
            <div>
              <h2 class="text-slate-600 text-lg font-medium inline">
                Data transforms
              </h2>
              <p class="text-slate-400 text-lg inline">
                &mdash; Enforce taxonomies, rename fields, parse data, and
                extract fields. Pre-built functions let you work with data from
                OpenTelemetry, Datadog or JSON using our structured event model.
              </p>
            </div>
          </div>
          <div class="flex mb-6">
            <div>
              <h2 class="text-slate-600 text-lg font-medium inline">
                Fully observable
              </h2>
              <p class="text-slate-400 text-lg inline">
                &mdash; See your telemetry in real-time with live sampling of
                events and metrics on ingestion, filtering, transform and
                deliverability. Preview the impact of changes with live data
                before deploying.
              </p>
            </div>
          </div>
          <div class="flex mb-6">
            <div>
              <h2 class="text-slate-600 text-lg font-medium inline">
                Instant deploys
              </h2>
              <p class="text-slate-400 text-lg inline">
                &mdash; Empower anyone on the team to instantly deploy pipeline
                changes through the API or UI and swiftly roll back those
                changes in the event of any unexpected impacts or issues.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Join Waitlist Section -->
    <section class="bg-teal-50 border-t border-b border-teal-100">
      <!-- add background image -->
      <div class="max-w-7xl mx-auto px-8 py-20">
        <div class="text-center">
          <p class="text-4xl text-teal-400 w-4/6 mx-auto mb-4">
            Want more control over your telemetry?
          </p>
          <a href="/waitlist/">
            <button
              class="mt-4 bg-teal-400 text-lg text-white py-3 px-8 rounded hover:bg-teal-500"
            >
              Request Early Access
            </button>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.normal {
  animation: orbit-normal 20s linear infinite;
}
.reverse {
  animation: orbit-reverse 20s linear infinite;
}

@keyframes orbit-normal {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes orbit-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
